import React from "react";
import { Link } from "react-router-dom";
import I18n from "../I18n";
import { connect } from "react-redux";
import flags from "../lib/flags";
import { reProfileMe } from "../selectors";
import coin from "../assets/images/coin.png";

function getEmoji(itemID){
  const ITEM_EMOJI = {
    36: "🌏",
    118: "📆",
    669: "🏰",
    1095: "🗼",
    1166: "🎵"
  }

  return ITEM_EMOJI[itemID] ? ITEM_EMOJI[itemID] : "🎲"
}

class StampStoreMenu extends React.Component {
  render() {
    const { cachedStore, me, ipcountry } = this.props;
    return (
      <div className="sticky-top pt-3 pb-3 sidebar">
        <div className="card">
          <div
            className="card-header banner-img"
          />
          <ul className="list-group list-group-flush">
            <Link
              className="list-group-item list-group-item-action btn"
              to="/store"
            >
              <h4 className="m-0">{I18n.t("STAMP_STORE")}</h4>
            </Link>
            {!!cachedStore &&
              cachedStore.store &&
              cachedStore.location &&
              cachedStore.location.length > 0 && (
                <Link
                  className="list-group-item list-group-item-action btn"
                  to="/store/list/exclusive"
                >
                  <span className="noto-emoji icon-sw">{!!flags[cachedStore.store] && flags[cachedStore.store].emoji}</span>
                  {" " + I18n.country(cachedStore.store)}
                </Link>
              )}

            {!!cachedStore &&
              cachedStore.store &&
              cachedStore.random && 
              cachedStore.random.map( item => {
                return (<button
                  key={'random-menu-'+item.id}
                  className="list-group-item list-group-item-action btn"
                  onClick={() => {
                    this.props.showStamp(item);
                  }}
                >
                  <span className="noto-emoji icon-sw">{getEmoji(item.id)}</span>
                  {' ' + I18n.t(item.title)}
                </button>)
              })}
            <Link
              className="list-group-item list-group-item-action btn"
              to="/store/list/bestsellers"
            >
              <span className="noto-emoji icon-sw">🔥</span>
              { ' ' +I18n.t("BEST_SELLERS")}
            </Link>
            <Link
              className="list-group-item list-group-item-action btn"
              to="/store/list/single"
            >
              <i className="icon-stamp-small-01 text-lighter icon-sw" />
              {" " + I18n.t("STAMP_SINGLE")}
            </Link>
            <Link
              className="list-group-item list-group-item-action btn"
              to="/store/list/set"
            >
              <i className="icon-grid text-lighter icon-sw" />
              {" " + I18n.t("STAMP_SET")}
            </Link>
            <Link
              className="list-group-item list-group-item-action btn"
              to="/store/list/comingsoon"
            >
              <span className="noto-emoji icon-sw">⏰</span>
              {" " + I18n.t("Coming Soon")}
            </Link>
          </ul>
        </div>
        <div className="card mt-2">
          <div className="card-body align-items-center d-flex row p-2 m-0 row">
            <div className="mr-auto my-2 col-12 col-xl-7 p-0">
              <div className="strong small">
                <img
                  src={coin}
                  height={15}
                  alt={I18n.t("MY_COINS")}
                  className="ml-2 mr-1"
                />
                <span className="mr-1">{I18n.t("MY_COINS") + ": "}</span>
                {me.coins}
              </div>
            </div>

            {ipcountry !== "IN" && ipcountry !== "RU" && (
              <Link
                to="/topup"
                className="btn btn-secondary btn-sm small col-12 col-xl-5 w-100"
              >
                {I18n.t("PURCHASE_COINS")}
              </Link>
            )}
          </div>
        </div>
        { me.role<20 && (
          <Link
                to="/upgrade"
                className="mt-2 d-block text-primary btn strong coinsbg collection-info border"
          >
            <i className="icon-plus-inline d-block mb-1 text-gold" />
            {' ' + I18n.t('MONTHLY_COINS_DESC',{ coins: 100 })}
          </Link>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    cachedStore: state.slowly.store,
    ipcountry: state.slowly.country,
  };
};

export default connect(mapStateToProps, null)(StampStoreMenu);
